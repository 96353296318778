import React, { useContext } from "react";
import PropTypes from "prop-types";
import { TypeformContext } from "../TypeformContext/TypeformContext";

const TypeformButton = ({ className, children }) => {
  // eslint-disable-next-line no-unused-vars
  const [showModal, setShowModal] = useContext(TypeformContext);
  const onShowModal = () => {
    setShowModal(true)
    if(typeof window != 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'hubspotFormShow',
        formId: '8859ea41-9852-4055-b49f-9fe7875f643e'
      })
    }
  }

  const buttonClick = (event, e2) => {
    try {
      if(event.currentTarget.hasAttribute('data-cta'))
        window.website_offer_cta = event.currentTarget.getAttribute('data-cta')
      else
        window.website_offer_cta = event.currentTarget.innerText

      let parent = event.currentTarget.closest('[data-cta-location]')
      if(parent)
        window.website_offer_cta = parent.getAttribute('data-cta-location') + ' | ' + window.website_offer_cta
    }
    catch(e) {
      console.error(e)
    }
  }

  className += ' lead-button'

  return (
    <span onClick={(event) => {onShowModal();buttonClick(event);}} className={className} role="button">
      {children}
    </span>
  );
};

export default TypeformButton;

TypeformButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
