/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import BaseLink from "../BaseLink/BaseLink";
import HeaderSubMenu
  from "./HeaderSubMenu";
import Button
  from "../Button/ButtonV2";

const HeaderMenu = ({ menu }) => {
  return (
    <>
      <nav id="hamnav">
        <input type="checkbox" id="hamburger" />
        <label htmlFor="hamburger" className="ham-label">
          <div className="hamburger hamburger--squeeze" tabIndex="0" aria-label="Menu" role="button" aria-controls="navigation">
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </div>
        </label>
        <ul className="items" data-cta-location="Header">
          {menu.map(menuItem => {
            if(menuItem.childItems.nodes.length > 0) {
              return (
                <HeaderSubMenu {...menuItem} key={`${menuItem.id}_${menuItem.label}`} />
              )
            }
            else if(!menuItem.parentId) {
              return (
                <li className="menu-item" key={`${menuItem.id}_${menuItem.label}`}>
                  <BaseLink key={menuItem.label} href={menuItem.path}>{menuItem.label}</BaseLink>
                </li>
              )
            }
          })}
          <li className="menu-item">
            <Button
              href="#lead-form"
              className="py-3 px-4 xl:py-5 xl:px-7"
              color="red"
              bordered
            >
              Let‘s Talk
            </Button>
          </li>
          <li className="social-icons">
            <BaseLink href="https://www.facebook.com/disruptiveads/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="none"
              >
                <path
                  fill="#1B1C1C"
                  fillRule="evenodd"
                  d="M0 12.5698C0 18.7844 4.51354 23.9521 10.4167 25V15.9719H7.29167V12.5H10.4167V9.72188C10.4167 6.59687 12.4302 4.86146 15.2781 4.86146C16.1802 4.86146 17.1531 5 18.0552 5.13854V8.33333H16.4583C14.9302 8.33333 14.5833 9.09687 14.5833 10.0698V12.5H17.9167L17.3615 15.9719H14.5833V25C20.4865 23.9521 25 18.7854 25 12.5698C25 5.65625 19.375 0 12.5 0C5.625 0 0 5.65625 0 12.5698Z"
                  clipRule="evenodd"
                />
              </svg>
            </BaseLink>
            <BaseLink href="https://www.instagram.com/disruptiveads/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="none"
              >
                <path
                  fill="#1B1C1C"
                  fillRule="evenodd"
                  d="M7.347.075C8.68.014 9.105 0 12.5 0c3.396 0 3.82.015 5.152.075 1.332.06 2.241.273 3.037.58A6.15 6.15 0 0 1 22.902 2.1a6.107 6.107 0 0 1 1.441 2.212c.31.796.52 1.705.582 3.034.061 1.336.075 1.76.075 5.155 0 3.396-.015 3.82-.075 5.153-.06 1.33-.273 2.239-.582 3.035a6.133 6.133 0 0 1-1.44 2.214 6.13 6.13 0 0 1-2.214 1.441c-.796.31-1.705.52-3.035.582-1.334.061-1.758.075-5.154.075-3.395 0-3.82-.015-5.153-.075-1.33-.06-2.24-.273-3.035-.582a6.133 6.133 0 0 1-2.214-1.44 6.128 6.128 0 0 1-1.442-2.214c-.308-.796-.52-1.705-.581-3.035C.014 16.32 0 15.894 0 12.5c0-3.395.015-3.82.075-5.152.06-1.332.273-2.241.58-3.037A6.132 6.132 0 0 1 2.1 2.098 6.127 6.127 0 0 1 4.31.656C5.107.348 6.016.136 7.345.075h.002Zm10.204 2.25c-1.318-.06-1.713-.073-5.051-.073s-3.733.013-5.051.073c-1.22.056-1.88.26-2.322.43-.583.228-1 .497-1.437.935a3.877 3.877 0 0 0-.934 1.437c-.172.441-.375 1.103-.431 2.322-.06 1.318-.073 1.713-.073 5.051s.013 3.733.073 5.051c.056 1.22.26 1.88.43 2.322.2.542.52 1.034.935 1.437a3.88 3.88 0 0 0 1.437.934c.441.172 1.103.375 2.322.431 1.318.06 1.712.073 5.051.073 3.339 0 3.733-.013 5.051-.073 1.22-.056 1.88-.26 2.322-.43.583-.228 1-.497 1.437-.935a3.88 3.88 0 0 0 .934-1.437c.172-.441.375-1.103.431-2.322.06-1.318.073-1.713.073-5.051s-.013-3.733-.073-5.051c-.056-1.22-.26-1.88-.43-2.322-.228-.583-.497-1-.935-1.437a3.878 3.878 0 0 0-1.437-.934c-.441-.172-1.103-.375-2.322-.431Zm-6.648 14.028a4.169 4.169 0 0 0 4.546-6.802 4.17 4.17 0 1 0-4.546 6.802ZM7.957 7.957a6.425 6.425 0 1 1 9.086 9.086 6.425 6.425 0 0 1-9.086-9.086Zm12.393-.925a1.52 1.52 0 0 0-1.655-2.496 1.519 1.519 0 1 0 1.655 2.496Z"
                  clipRule="evenodd"
                />
              </svg>
            </BaseLink>
            <BaseLink href="https://www.linkedin.com/company/disruptive-advertising/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="26"
                fill="none"
              >
                <path
                  fill="#1B1C1C"
                  fillRule="evenodd"
                  d="M9.287 8.718h4.643v2.332c.67-1.34 2.384-2.547 4.96-2.547 4.94 0 6.11 2.67 6.11 7.568v9.074h-4.998v-7.958c0-2.79-.67-4.364-2.372-4.364-2.362 0-3.343 1.697-3.343 4.364v7.958h-5V8.718ZM.715 24.93h5V8.503h-5v16.429ZM6.43 3.145A3.215 3.215 0 1 1 0 3.286a3.215 3.215 0 0 1 6.43-.141Z"
                  clipRule="evenodd"
                />
              </svg>
            </BaseLink>
          </li>
        </ul>
        {/*<input className="menu-btn" type="checkbox" id="menu-btn"/>*/}
        {/*<label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>*/}
        {/*<ul className="mainmenu menu flex justify-end gap-x-5 align-middle xl:gap-x-7">*/}
        {/*  {menu.map((menuItem) => {*/}
        {/*    return menuItem.childItems.nodes.length > 0 ? (*/}
        {/*      <HeaderSubMenu {...menuItem} key={menuItem.id} />*/}
        {/*    ) : (*/}
        {/*      <li key={menuItem.id}>*/}
        {/*        <BaseLink href={menuItem.path} className="menu-link menu-bar-link">{menuItem.label}</BaseLink>*/}
        {/*      </li>*/}
        {/*    )*/}
        {/*  })}*/}
        {/*</ul>*/}
      </nav>
    </>
  );
};

HeaderMenu.propTypes = {
  menu: PropTypes.array.isRequired,
};

export default HeaderMenu;
